import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import { Button } from '@trexity/common-client/lib/components'
import MerchantSignUpButton from '../../components/merchantSignUpButton'

export default function LaBottegaNicastro () {
  const [videoVisible, setVideoVisible] = React.useState(false)

  return (
    <Layout type='merchant'>
      <section className='well merchant-profile mt' style={{ maxWidth: 800, overflow: 'hidden' }}>
        <div>
          {!videoVisible ? (
            <div
              className="merchant-profile-yt responsive-video"
              style={{ backgroundImage: 'url("/img/merchant-profiles/youtube-nicastro.jpg")' }}
              onClick={() => setVideoVisible(!videoVisible)}
            />
          ) : null}
          {videoVisible ? (
            <div className='responsive-video' style={{ borderRadius: 0 }}>
              <iframe
                src="https://www.youtube.com/embed/NZs_KJyqv0Y?rel=0&amp;modestbranding=1&amp;autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : null}
        </div>
        <div className="inner">
          <span className='mice'>Joined Apr 17, 2020</span>
          <h2 className='mb-0'>La Bottega</h2>
          <p className='lede mb'>Q’s &amp; A’s with Rocco &amp; Larissa from La Bottega</p>
          <div>
            <h4 className='mt'>La Bottega really has become an Ottawa Institution, how does that happen?</h4>
            <p className='mb-2'><strong>Rocco:</strong> I think we became an institution by not focusing on becoming an institution, just coming to work every day, being humble, trying to build a quality team of people who are passionate about what they do. So hard work, being nice, treating customers fairly, really simple, business 101 in my view…and yeah, some long days too.</p>

            <h4>What makes La Bottega special?</h4>
            <p><strong>Larissa:</strong> Oh, La Bottega is special in so many ways. I mean, I think it’s one of the last few solely family owned businesses in Ottawa. The owners are here every day, very hands on. It’s truly a passionate Italian food family.</p>
            <p className='mb-2'><strong>Rocco:</strong> I think first and foremost, the people, so the team that we have working for us here. They definitely bring a personality that you might not be exposed to elsewhere; a lot of experience and a passion for what it is we’re trying to do here. Definitely our location in the market is a special one as well, that doesn’t hurt either.</p>

            <div className='mb-2'>
              <blockquote className='quote mb-2'>
                It’s been important to provide a last mile delivery option, which we never would’ve envisioned being this easy.
              </blockquote>
            </div>

            <h4>Tell us about an average day in the store.</h4>
            <p><strong>Larissa:</strong> Oh, the average day in the store is busy. It always starts with definitely some espressos, cappuccinos. And then customers start coming in shopping. We have a busy lunch service that’s restarted now, we pack grocery orders, we do catering orders. We used to run cooking classes, wine tasting. So hopefully we’ll get back to those. Yeah but it’s a busy day, a lot of stuff coming in, pallets, trucks, unloading, stocking, restocking. It’s a constant cycle.</p>
            <p className='mb-2'><strong>Rocco:</strong> I would say every day has its own identity, you just don’t know what you’re going to face. It’s a lot of unpredictability and it’s kind of what makes the day exciting. Definitely, no lack of excitement and predictability.</p>

            <h4>Tell us a little bit about some of the challenges you guys have had.</h4>
            <p><strong>Rocco:</strong> Actually business has grown steadily and consistently over the last few years. The pandemic definitely created a bit of a shift in what we’ve had to prioritize, and it’s been important to provide a last mile delivery option, which we never would’ve envisioned being this easy if you would’ve asked us two years ago. Trexity has allowed us to offer and execute that for our customers.</p>
            <p className='mb-2'><strong>Larissa:</strong> Yes, definitely the pandemic affected us greatly being in the ByWard Market. A big challenge was just people not going out shopping. 80% of our business did come from the businesses in the downtown core so that got liquidated overnight. So we had to very quickly move online. We put our whole grocery store online. We started offering delivery, curb-side pickup and that has grown over the past two years now. So that was challenging. Definitely took a lot of work and we had to definitely shift quickly, change how we did business and that was definitely very challenging.</p>

            <h4>How has Home delivery helped?</h4>
            <p><strong>Larissa:</strong> Home delivery has saved us the first six months of the pandemic. We had everybody in the store involved just packing grocery orders, honestly shopping and packing them up. We had stacks of grocery orders every day. So yeah. So now we have a good team.</p>
            <p className='mb-2'>Trexity came in at the best possible time. They connected with us to partner on home delivery and it just blossomed. We would have not been able to manage all our home deliveries ourselves. Just the fact that we could call a car, it would get here so quickly and then to somebody’s house that same day..it was great!</p>

            <div className='row align-center space-between'>
              <div className='col mb d-mb-0'>
                <a href="https://www.labottega.ca/" target="_blank" rel="noreferrer">
                  <img src="/img/merchants/bottega.jpg" alt="La Bottega Nicastro logo" style={{ maxWidth: 120 }} />
                </a>
              </div>

              <div className='col'>
                <a href='https://www.labottega.ca/' className='cta-link' target="_blank" rel="noreferrer">Visit La Bottega Nicastro <i className="material-icons">keyboard_arrow_right</i></a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h2>Keep the local community</h2>
              <p className='mb'>When people do what they love, they do it better.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
